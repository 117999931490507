import React from 'react'
import Img from 'gatsby-image'

const Footer = (props) => {

    const theYear = new Date().getFullYear();

    return (
        <footer className="bg-beige-100 p-2 sm:px-8">

            <Img className="w-32 my-4 mx-auto" fluid={props.img} />

            <p className="text-center">Copyright © <span className="year">2018</span> Curious Book Co. All rights reserved.</p>

        </footer>
    )
}

export default Footer;
