import React from 'react'
import Img from 'gatsby-image'
import Button from './button.js'
import PhoneNumber from './phoneNumber.js'

const Header = (props) => {
    return (
        <header className={`fixed top-0 left-0 z-50 w-full max-w-full mx-auto bg-beige-100 p-2 flex flex-wrap items-center justify-start sm:px-8 md:py-0 md:px-16 ${props.classes}`}>

            <div className="order-first w-1/2 sm:w-1/3 px-2 text-center">
              <Button url="#form" text="Submit Enquiry" classes="mx-auto" />
            </div>

            <div className="w-full sm:w-1/3 order-last">
              <Img className="w-32 my-4 mx-auto" fluid={props.img} />
            </div>

            { /*
            <div className="order-first text-center px-2 pr-0 w-1/2 sm:w-1/3 sm:order-last">
              <PhoneNumber classes="inline-block text-center border border-teal-500 p-2 lg:w-auto" />
            </div>
            */ }

        </header>
    )
}

export default Header;
